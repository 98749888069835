import {getUniqArrBy} from '../../../utils/selectorUtils'
import {LOCAL_STORAGE_KEYS} from '../../../config/enums'
import {CurrentStepContextType} from '../../../@types/contextes'
import React, {useContext} from 'react'
import {CurrentStepContext} from '../../../utils/contextes/CurrentStepContext'
import {LABEL_DATA_EXTRACTION} from './constants'
import {DistributorContext} from "../../../utils/contextes/DistributorContext";

export const useCurrentStepContext = () => {
	const context = React.useContext<CurrentStepContextType>(CurrentStepContext)
	if (context === undefined) {
		throw Error("CurrentStep must be used inside of selector")
	}
	return context
}

/**
 * Extraction des diamètres internes d'un tuyau
 * @param hoses
 */
export const extractInsideDiameters = (hoses: Array<HoseType>): Array<DiametersType> => {
	let newHosesArray: Array<HoseType> = [];

	for (let h in hoses) {
		const currentHose = hoses[h]

		if (!newHosesArray.find(hose => hose.inside_diameter_mm === currentHose.inside_diameter_mm)) {
			newHosesArray = newHosesArray.concat([currentHose])
		} else {
			let hoseIndex = newHosesArray.findIndex(hose => {
					let hoseDiameterSize = (hose.inside_diameter_size !== null && hose.inside_diameter_size.charAt(0) === '-')
						? hose.inside_diameter_size.substring(1)
						: hose.inside_diameter_size
					let currentHoseDiameterSize = (currentHose.inside_diameter_size !== null && currentHose.inside_diameter_size.charAt(0) === '-')
						? currentHose.inside_diameter_size.substring(1)
						: currentHose.inside_diameter_size

					return hose.inside_diameter_mm === currentHose.inside_diameter_mm
						&& (hose.inside_diameter_size === null || hoseDiameterSize < currentHoseDiameterSize)
				}
			);
			if (hoseIndex >= 0) {
				newHosesArray[hoseIndex] = {
					...newHosesArray[hoseIndex],
					inside_diameter_size: currentHose.inside_diameter_size
				}
			}
		}
	}

	return newHosesArray.map(it => {
		return {
			inside_diameter_mm: Number(it.inside_diameter_mm),
			inside_diameter_inch: it.inside_diameter_inch,
			inside_diameter_size: it.inside_diameter_size
		}
	})
}

/**
 * Extrait la valeur maximale de pression de la liste des tuyaux
 * @param hoses
 */
export const extractMaxPressure = (hoses: Array<HoseType>): number => Math.max(...hoses.map(o => o.max_working_pressure_bar))

/**
 * Extrait les valeurs de vacuum pressure
 * @param hoses
 */
export const extractVacuumPressure = (hoses: Array<HoseType>): Array<VacuumDataType> => {
	return getUniqArrBy([LABEL_DATA_EXTRACTION.VACUUM_PRESSURE_BAR], hoses).map(it => {
		return {
			vacuum_pressure: it.vacuum_pressure_bar
		}
	}).filter(data => data.vacuum_pressure !== null)
}

/**
 * Extrait la valeur de température la plus basse
 * @param hoses
 */
export const extractMinWorkingTemperature = (hoses: Array<HoseType>): number => Math.min(...hoses.map(o => o.min_working_temperature_c))

/**
 * Extrait la valeur de température la plus haute
 * @param hoses
 */
export const extractMaxWorkingTemperature = (hoses: Array<HoseType>): number => Math.max(...hoses.map(o => o.max_working_temperatures && o.max_working_temperatures.length > 0 ? Math.max(...o.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)).map(it => it.max_working_temperature)) : 0))

export const extractHosesColors = (hoses: Array<HoseType>): Array<string> => {
	let colorArray = getUniqArrBy([LABEL_DATA_EXTRACTION.COLOR], hoses).map((hose: HoseType) => hose.color);
	colorArray.unshift('-')
	return colorArray
}

/**
 * Génération des filtres pour la récupération des hoses via l'API
 */
export const generateHoseFiltersForApi = (token: string, standardCode?: string, typeCode?: string): string => {
	const standard = standardCode ? standardCode : localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STANDARD)
	const type = typeCode ? typeCode : localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_TYPE)
	const insideDiameterMm = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM)
	const vacuum = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE)
	const pressure = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE)
	const minTemperature = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE)
	const maxTemperature = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE)

	const filters: HoseFiltersType = {
		media_code: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)!
	}

	if (standard) {
		filters.standard_code = standard
	}
	if (type) {
		filters.type = type
	}
	if (insideDiameterMm) {
		filters.inside_diameter_mm = insideDiameterMm
	}
	if (pressure) {
		filters.max_working_pressure_bar = Number(pressure)
	}
	if (vacuum) {
		filters.vacuum_pressure_bar = Number(vacuum)
	}
	if (minTemperature && maxTemperature) {
		filters.min_working_temperature_c = Number(minTemperature)
		filters.max_working_temperature = Number(maxTemperature)
	}

	filters.store_token = token

	return JSON.stringify(filters)
}

/**
 * Génère un hose avec les données présentes dans le local storage
 */
export const generateHose = (): HoseType => {
	const { token } = useContext(DistributorContext)
	return {
		inside_diameter_mm: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM)!,
		inside_diameter_inch: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_INCH)!,
		standards_met: [{ standard_code: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STANDARD)!, type: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_TYPE)!, token: token }],
		max_working_pressure_bar: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE)!),
		vacuum_pressure_bar: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE)!,
		min_working_temperature_c: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE)!),
		max_working_temperatures: [{
			max_working_temperature: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE)!),
			media_code: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)!
		}],
		min_bending_radius_mm: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_BENDING_RADIUS_MM)!),
		image: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_IMAGE)!,
		color: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_COLOR)!,
		abrasion_resistance: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_ABRASION_RESISTANCE)!),
		inside_diameter_size: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_SIZE)!,
		part_number: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER)!,
		series_name: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME)!,
		store_part_number: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER)
	}
}
