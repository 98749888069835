import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Stack, styled, Tooltip } from '@mui/material'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import LabelTitle from '../../../../components/typo/LabelTitle'
import StretchRadioButton from '../../../../components/form/radio/stretch/StretchRadioButton'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'
import HoseImageSection from '../../../../components/image/HoseImageSection'
import ProductImage from '../../../../components/image/ProductImage'
import { concatFittingInformation, getUniqArrBy } from '../../../../utils/selectorUtils'
import { getFittingsWithFilters } from '../../services/fittingsApi'
import { generateFittingsFilters, RANK_FITTING_A, sortFittingConfiguration } from '../../utils/fittingsUtils'
import Loader from '../../../../components/global/Loader'
import ErrorLabel from '../../../../components/typo/ErrorLabel'
import { useCurrentStepContext } from '../../utils/utils'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import FittingInformation from '../../../../components/typo/FittingInformation'
import { CustomStoreElementsContext } from '../../../../utils/contextes/CustomStoreElementsContext'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const StandardsContainer = styled(Stack)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '12px',
		gap: '12px',
		backgroundColor: theme.palette.assemblyPanel.main,
		borderRadius: '12px',
		width: '40%'
	})
)

const FittingConfiguration = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [fittings, setFittings] = useState<Array<FittingType>>([])
	const [fittingsDisplayed, setFittingsDisplayed] = useState<Array<FittingType>>([])
	const [filterSelected, setFilterSelected] = useState<string>()
	const [fittingSelected, setFittingSelected] = useState<string>()
	const [standards, setStandards] = useState<Array<StandardFilterType>>([])
	const { delaiStandard, isCustomStore } = useContext(CustomStoreElementsContext)
	const { setVisibilitySnackbar } = useContext(SnackContext)

	useEffect(() => {
		getFittingsWithFilters(generateFittingsFilters(currentStep.rank!))
			.then((data: Array<FittingType>) => {
				const fittingsFiltered = data.reduce((a1: FittingType[], c1)=> {
					let b1 = a1.find((v1:FittingType) => v1.standard === c1.standard && v1.gender === c1.gender && v1.shape === c1.shape && v1.specific_description === c1.specific_description)
					if (!b1){
						a1.push(c1)
					} else if (b1.store_part_number === null && c1.store_part_number !== null){
						b1.store_part_number = c1.store_part_number
					}
					return a1;
				}, [])
				setFittingsDisplayed(fittingsFiltered.sort(sortFittingConfiguration))
				setFittings(data.sort(sortFittingConfiguration))

				const standardsFiltered = data.reduce((a : StandardFilterType[] , c) => {
					let b = a.find((v:StandardFilterType) => v.name === c.standard)
					if (!b){
						console.log(c.store_part_number)
						console.log(c.store_part_number!==null)
						a.push({
							name:c.standard,
							tooltip:c.store_part_number!==null
						});
					} else {
						b.tooltip = b.tooltip || c.store_part_number!==null
						console.log(c.store_part_number)
						console.log(b.tooltip)
					}
					return a;
				}, []);
				setStandards(standardsFiltered)

			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		const fitting = fittings.find(it => it.part_number === fittingSelected)
		if (fitting && fittingSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_STANDARD : LOCAL_STORAGE_KEYS.FITTING_B_STANDARD,
						value: fitting.standard
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_STANDARD_TRANSLATION : LOCAL_STORAGE_KEYS.FITTING_B_STANDARD_TRANSLATION,
						value: fitting.translated_standard
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_GENDER : LOCAL_STORAGE_KEYS.FITTING_B_GENDER,
						value: fitting.gender
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_GENDER_TRANSLATION : LOCAL_STORAGE_KEYS.FITTING_B_GENDER_TRANSLATION,
						value: fitting.translated_gender
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SHAPE : LOCAL_STORAGE_KEYS.FITTING_B_SHAPE,
						value: fitting.shape
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SHAPE_TRANSLATION : LOCAL_STORAGE_KEYS.FITTING_B_SHAPE_TRANSLATION,
						value: fitting.translated_shape
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION : LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION,
						value: fitting.specific_description
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION_TRANSLATION : LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION_TRANSLATION,
						value: fitting.translated_specific_description
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_MAX_WORKING_PRESSURE : LOCAL_STORAGE_KEYS.FITTING_B_MAX_WORKING_PRESSURE,
						value: fitting.max_working_pressure_bar
					},
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER : LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER,
						value: fitting.store_part_number
					}
				]
			})
		}
	}, [filterSelected, fittingSelected, fittings])

	useEffect(() => {
		if (filterSelected) {
			setFittingsDisplayed(getUniqArrBy(['standard', 'shape', 'gender', 'specific_description'], fittings.filter((fitting: FittingType) => fitting.standard.includes(filterSelected))))
		}
	}, [filterSelected])


	return isLoading ? (
		<Loader />
	) : (
		<>
			<Stack flexDirection="row" alignItems="flex-start" gap="24px" alignSelf="stretch">
				<StandardsContainer>
					<LabelTitle padding="24px">{t('screens.selector.fitting.configuration.standardFilter')}</LabelTitle>
					{standards.map((filter: StandardFilterType) => (
						<>
							<StretchRadioButton
								key={filter.name}
								label={filter.name}
								value={filter.name}
								radioBtnSelected={filterSelected}
								setRadioBtnSelected={setFilterSelected}
								tooltip={filter.tooltip}
							/>
						</>
					))}
				</StandardsContainer>
				<Stack alignItems="flex-start" alignSelf="stretch" width="60%">
					<LabelTitle padding="24px">{t('screens.selector.fitting.configuration.selectConfiguration')}</LabelTitle>
					{(fittingsDisplayed && fittingsDisplayed.length > 0) ? fittingsDisplayed.map((config: FittingType) => (
						<CheckRadioButton
							padding="0 24px"
							gap="24px"
							key={config.part_number}
							value={config.part_number}
							itemSelected={fittingSelected}
							setSelection={setFittingSelected}
							height="128px"
							cursor={'pointer'}
						>
							<>
								<HoseImageSection>
									<ProductImage image={config.image} />
								</HoseImageSection>
								<Stack flexDirection="column" alignItems="flex-start" alignSelf="stretch" justifyContent="center" gap="4px">
									<FittingInformation isSelected={fittingSelected === config.part_number}>{
										<>
											{config.standard}
											{
												config.store_part_number && isCustomStore && !!delaiStandard && (
													<Tooltip title={t(`${delaiStandard}`)}>
														<Link underline="none" >
															<AiOutlineInfoCircle color="#F0A400" style={{
																width: "20px",
																height: "17px"
															}} />
														</Link>
													</Tooltip>
												)
											}
										</>
									}</FittingInformation>

									<FittingInformation isSelected={fittingSelected === config.part_number}>
										{
											concatFittingInformation(
												!!config.translated_gender ? config.translated_gender : config.gender,
												!!config.translated_shape ? config.translated_shape : config.shape,
												!!config.translated_specific_description ? config.translated_specific_description : config.specific_description
											)
										}
									</FittingInformation>
								</Stack>
							</>
						</CheckRadioButton>
					)) : (
						<ErrorLabel>{t('errors.noData.fittings.configuration.fittings')}</ErrorLabel>
					)}
				</Stack>
			</Stack>
		</>
	)
}

export default FittingConfiguration