import { LOCAL_STORAGE_KEYS } from '../../../config/enums'
import { getUniqArrBy } from '../../../utils/selectorUtils'
import { LABEL_DATA_EXTRACTION } from './constants'
import { LOCAL_STORAGE_KEY } from "../../../utils/constants";

export const RANK_FITTING_A = 2
export const ASSEMBLY_RANK_STEP = 4

/**
 * Génération des filtres pour la récupération des fittings depuis l'API
 * @param rank
 */
export const generateFittingsFilters = (rank: number): string => {
	const hosePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER)
	const fittingAMaterial = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL)
	const fittingBMaterial = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL)
	const fittingAStandard = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STANDARD)
	const fittingBStandard = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STANDARD)
	const fittingAGender = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_GENDER)
	const fittingBGender = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_GENDER)
	const fittingAShape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE)
	const fittingBShape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SHAPE)
	const fittingASpecificDescription = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION)
	const fittingBSpecificDescription = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION)
	const storeToken = localStorage.getItem(LOCAL_STORAGE_KEY.STORE_TOKEN)

	if (hosePartNumber) {
		const filters: FittingFiltersType = {
			hose_part_number: hosePartNumber,
			hose_inside_diameter_mm: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM)),
			max_working_pressure_bar: Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE)),
			language: localStorage.getItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE)
		}
		if (rank === RANK_FITTING_A) {
			if (fittingAMaterial) {
				filters.material = fittingAMaterial
			}
			if (fittingAStandard) {
				filters.standard = fittingAStandard
			}
			if (fittingAGender) {
				filters.gender = fittingAGender
			}
			if (fittingAShape) {
				filters.shape = fittingAShape
			}
			if (fittingASpecificDescription) {
				filters.specific_description = fittingASpecificDescription
			}
		} else {
			if (fittingBMaterial) {
				filters.material = fittingBMaterial
			}
			if (fittingBStandard) {
				filters.standard = fittingBStandard
			}
			if (fittingBGender) {
				filters.gender = fittingBGender
			}
			if (fittingBShape) {
				filters.shape = fittingBShape
			}
			if (fittingBSpecificDescription) {
				filters.specific_description = fittingBSpecificDescription
			}
		}

		if (storeToken) {
			// TODO : remplacer le store token par celui présent dans le token Okta
			filters.store_token = storeToken
		}

		return JSON.stringify(filters)
	}
	return JSON.stringify({})
}

/**
 * Extrait la liste des materials
 * @param fittigns
 */
export const extractMaterials = (fittigns: Array<FittingType>) => {
	return getUniqArrBy([LABEL_DATA_EXTRACTION.MATERIAL], fittigns).map((fitting: FittingType) => fitting.material).sort()
}

/**
 * Tri les fitting dans l'ordre croissant par taille
 * @param fitting1
 * @param fitting2
 */
export const sortFittingSize = (fitting1: FittingType, fitting2: FittingType) => {
	const fitting1Size = Number(fitting1.size)
	const fitting2Size = Number(fitting2.size)
	if (fitting1Size > fitting2Size) return 1
	if (fitting1Size < fitting2Size) return -1
	return 0
}

/**
 * Tri les fittings dans l'ordre croissant par genre, forme et description
 * @param fitting1
 * @param fitting2
 */
export const sortFittingConfiguration = (fitting1: FittingType, fitting2: FittingType) => {
	if (fitting1.store_part_number!==null && fitting2.store_part_number===null) return -1
	if (fitting1.store_part_number===null && fitting2.store_part_number!==null) return 1

	if (fitting1.gender > fitting2.gender) return 1
	if (fitting1.gender < fitting2.gender) return -1

	if (fitting1.shape > fitting2.shape) return 1
	if (fitting1.shape < fitting2.shape) return -1

	if (fitting1.specific_description > fitting2.specific_description) return 1
	if (fitting1.specific_description < fitting2.specific_description) return 1

	return 0
}
