import React, {FC, useContext, useEffect, useState} from 'react'
import HoseSelectionHeader from '../hoseSelection/HoseSelectionHeader'
import HoseSelectionBody from '../hoseSelection/body/HoseSelectionBody'
import {getHosesWithFilter, getStandards} from '../../services/hosesApi'
import Loader from '../../../../components/global/Loader'
import {LOCAL_STORAGE_KEYS} from '../../../../config/enums'
import BasicError from '../../../../components/errors/BasicError'
import {useTranslation} from 'react-i18next'
import {extractHosesColors, generateHoseFiltersForApi, useCurrentStepContext} from '../../utils/utils'
import {STATUS_PRODUCT} from '../../../../utils/constants'
import {SnackContext} from '../../../../utils/contextes/SnackContext'
import {extractStandard, extractType, sortHose} from '../../../../utils/selectorUtils'
import {STANDARDS} from '../../utils/constants'
import {DistributorContext} from '../../../../utils/contextes/DistributorContext';

const HoseSelection: FC = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()
	const { token } = useContext(DistributorContext)

	const [colors, setColors] = useState<Array<string>>([])
	const [standards, setStandards] = useState<Array<StandardType>>([])
	const [distinctStandards, setDistinctStandards] = useState<Array<string>>([])
	const [distinctTypes, setDistinctTypes] = useState<Array<string>>([])
	const [colorSelected, setColorSelected] = useState<string>('-')
	const [abrasionResistance, setAbrasionResistance] = useState<boolean>(false)
	const [standardSelected, setStandardSelected] = useState<string>(extractStandard())
	const [typeSelected, setTypeSelected] = useState<string>(extractType())
	const [hoseSelection, setHoseSelection] = useState<string>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [products, setProducts] = useState<Array<HoseType>>([])
	const [productsDisplayed, setProductsDisplayed] = useState<Array<HoseType>>([])
	const { setVisibilitySnackbar } = useContext(SnackContext)

	useEffect(() => {
		getStandards(token)
			.then((res: { standards: Array<StandardType> }) => {
				setStandards(res.standards)
				let ds : string[] = []
				res.standards.map(standard => {
					if (standard.standard_code && ds.indexOf(standard.standard_code) < 0){
						ds.push(standard.standard_code)
					}
				})
				let distinctStandards = ds.sort();
				distinctStandards.unshift('-')
				setDistinctStandards(distinctStandards)
				let dt : string[] = []
				res.standards.map(standard => {
					if (standard.type != null && dt.indexOf(standard.type) < 0){
						dt.push(standard.type)
					}
				})
				let distinctTypes = dt.sort();
				distinctTypes.unshift('-')
				setDistinctTypes(distinctTypes)

			})
			.catch(() => setVisibilitySnackbar(true))
	}, [])

	useEffect(() => {
		let standardFilter = standardSelected
		if (standardSelected === STANDARDS[0]) {
			standardFilter = ""
		}
		let typeFilter = typeSelected
		if (typeSelected === STANDARDS[0]) {
			typeFilter = ""
		}
		getHosesWithFilter(generateHoseFiltersForApi(token!!, standardFilter, typeFilter))
			.then((hoses: Array<HoseType>) => {
				let hosesSorted: Array<HoseType> = hoses.sort(sortHose)
				if (abrasionResistance) {
					hosesSorted = hosesSorted.filter((hose: HoseType) => hose.abrasion_resistance)
				}
				if (colorSelected) {
					hosesSorted = hosesSorted.filter((hose: HoseType) => colorSelected === '-' ? true : hose.color === colorSelected)
				}
				setProductsDisplayed(hosesSorted)
				setProducts(hoses.sort(sortHose))
				setColors(extractHosesColors(hoses))
				if (hosesSorted.length > 0) {
					setHoseSelection(hosesSorted[0].part_number)
				}
			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))

	}, [standardSelected, typeSelected])

	useEffect(() => {
		let newProductsDisplayed = [...products]
		if (abrasionResistance) {
			newProductsDisplayed = newProductsDisplayed.filter((hose: HoseType) => hose.abrasion_resistance)
		}
		if (colorSelected) {
			newProductsDisplayed = newProductsDisplayed.filter((hose: HoseType) => colorSelected === '-' ? true : hose.color === colorSelected)
		}
		setProductsDisplayed(newProductsDisplayed.sort(sortHose))
	}, [abrasionResistance, colorSelected])

	useEffect(() => {
		if (hoseSelection) {
			const hoseSelected = products.find(it => it.part_number === hoseSelection)
			if (hoseSelected) {
				updateCurrentStep({
					rank: currentStep.rank,
					subStepKey: currentStep.subStepKey,
					lastSubStep: currentStep.lastSubStep,
					firstSubStep: currentStep.firstSubStep,
					values: [
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_IMAGE,
							value: hoseSelected.image
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER,
							value: hoseSelected.part_number
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME,
							value: hoseSelected.series_name
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STATUS,
							value: hoseSelected.store_part_number ? t(`${STATUS_PRODUCT.REGULAR}`) : t(`${STATUS_PRODUCT.ON_REQUEST}`)
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER,
							value: hoseSelected.store_part_number
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_COLOR,
							value: hoseSelected.color
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_ABRASION_RESISTANCE,
							value: hoseSelected.abrasion_resistance
						},
						{
							localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_BENDING_RADIUS_MM,
							value: hoseSelected.min_bending_radius_mm
						}
					]
				})
			}
		}
	}, [hoseSelection])

	return isLoading ? (
		<Loader />
	) : (
		<>
			<HoseSelectionHeader
				colorSelected={colorSelected}
				setColorSelected={setColorSelected}
				abrasionResistance={abrasionResistance}
				setAbrasionResistance={setAbrasionResistance}
				standardSelected={standardSelected}
				setStandardSelected={setStandardSelected}
				colors={colors}
				standards={distinctStandards}
			/>
			{(productsDisplayed && productsDisplayed.length > 0) ? (
				<HoseSelectionBody hoseSelection={hoseSelection} setHoseSelection={setHoseSelection} hoses={productsDisplayed} />
			) : <BasicError message={t('errors.noData.hoses')} />}
		</>
	)
}

export default HoseSelection
