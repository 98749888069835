import React, { FC, useContext, useMemo } from 'react'
import { extractDataUtilsForAssemblyProgressionPanel } from '../../../../utils/selectorUtils'
import { useTranslation } from 'react-i18next'
import { Link, Stack, styled, Tooltip } from '@mui/material'
import ProductImage from '../../../image/ProductImage'
import HoseImageSection from '../../../image/HoseImageSection'
import PartNumber from '../../../selector/PartNumber'
import StatusTypo from '../../../typo/StatusTypo'
import { PartDetailDialog } from '../../../selector/PartDetailDialog'
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CustomStoreElementsContext } from "../../../../utils/contextes/CustomStoreElementsContext";
import { STATUS_PRODUCT } from "../../../../utils/constants";

const SeriesName = styled(Stack)(
	({ theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '18px',
		color: theme.palette.text.primary,
		marginBottom: '6px'
	})
)

const ProgressDoneCardItem: FC<ProgressDoneCard> = ({ step }) => {
	const { t } = useTranslation()
	const { delaiStandard, delaiOnRequest, nomCommercial, isCustomStore } = useContext(CustomStoreElementsContext)
	const productDataUtils = useMemo(() => {
		return extractDataUtilsForAssemblyProgressionPanel(step)
	}, [step])
	console.log(productDataUtils?.status)
	console.log(t(STATUS_PRODUCT.ON_REQUEST))
	const isSelectedState = false

	return (
		<Stack flexDirection="row" alignItems="flex-start" gap="24px" alignSelf="stretch">
			<HoseImageSection>
				<ProductImage image={productDataUtils?.image!} />
			</HoseImageSection>
			<Stack flexDirection="column" justifyContent="space-between" alignItems="flex-start" gap="4px" alignSelf="none" flexGrow="1">
				<Stack flexDirection="column" alignItems="flex-start" gap="4" alignSelf="stretch">
					<SeriesName>{productDataUtils?.seriesName}</SeriesName>
					<StatusTypo isSelected={isSelectedState}>
						{t(productDataUtils?.status!)}
						{
							isCustomStore && !!delaiOnRequest && !!delaiStandard && (
								<Tooltip title={t(`${productDataUtils?.status == t(STATUS_PRODUCT.ON_REQUEST) ? delaiOnRequest : delaiStandard}`)}>
									<Link underline="none" >
										<AiOutlineInfoCircle color="#F0A400" style={{
											width: "20px",
											height: "17px"
										}} />
									</Link>
								</Tooltip>
							)
						}
					</StatusTypo>
					<PartNumber isSelected={isSelectedState} partNumber={t('components.assemblyProgressionPanel.partNumber', { partNumber: productDataUtils?.partNumber! })} />
					{
						productDataUtils?.storePartNumber
						&& <PartNumber isSelected={isSelectedState} partNumber={t('components.assemblyProgressionPanel.storePartNumber', { partNumber: productDataUtils?.storePartNumber!, store: !!nomCommercial ? nomCommercial : "STORE" })} />
					}
				</Stack>
				<PartDetailDialog partNumber={productDataUtils?.partNumber as string}
				                  seriesName={productDataUtils?.seriesName as string}
				                  isSelected={isSelectedState}
				/>
			</Stack>
		</Stack>
	)
}

export default ProgressDoneCardItem
