import React, { FC, useContext } from 'react'
import { FormControlLabel, Link, styled, Tooltip } from '@mui/material'
import BpRadio from '../BpRadio'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { CustomStoreElementsContext } from '../../../../utils/contextes/CustomStoreElementsContext'

const StretchRadioBtnContainer = styled(FormControlLabel, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ theme, isSelected }) => ({
		display: 'flex',
		backgroundColor: isSelected ? theme.palette.secondary.main : 'transparent',
		flexDirection: 'row',
		alignSelf: 'stretch',
		alignItems: 'center',
		padding: '5px 24px 5px 18px',
		border: `${isSelected ? '3px' : '1px'} solid ${theme.palette.assemblyPanel.card.disable}`,
		borderRadius: '6px',
		color: theme.palette.text.primary,
		height: '42px',
		marginLeft: '12px'
	})
)

const StretchRadioButton: FC<StretchRadioButtonType> = ({ label, value, radioBtnSelected, setRadioBtnSelected, tooltip }) => {
	const { t } = useTranslation()
	const { delaiStandard } = useContext(CustomStoreElementsContext)
	return <StretchRadioBtnContainer control={<BpRadio />} label={
			<>
					{label}
				{tooltip && <Tooltip title={t(`${delaiStandard}`)}>
						<Link underline="none" >
							<AiOutlineInfoCircle color="#F0A400" style={{
								width: "20px",
								height: "17px"
							}} />
						</Link>
					</Tooltip>}
			</>
		} isSelected={radioBtnSelected === value} value={value}  onClick={() => setRadioBtnSelected(value)} checked={radioBtnSelected === value} />
}

export default StretchRadioButton
