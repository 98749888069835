import React, { FC } from 'react'
import ProductCardGrid from './card/ProductCardGrid'
import ProductGrid from '../../../../modules/selector/components/ProductGrid'
import { Link } from '@mui/material'

const ProductGridSelector: FC<ProductGridSelectorType> = ({ productSelected, setProductSelected, products }) => {
	return (
		<ProductGrid>
			{products.map(product => (
				<Link key={product.part_number} onClick={() => {
					if (productSelected === product) {
						setProductSelected(null)
					} else {
						setProductSelected(product)
					}
				}} underline="none">
					<ProductCardGrid
						pathImg={product.image}
						label={product.part_number}
						description={product.series_name}
						active={productSelected ? product.part_number === productSelected.part_number : false}
						storePartNumber={product.store_part_number}
					/>
				</Link>
			))}
		</ProductGrid>
	)
}

export default ProductGridSelector